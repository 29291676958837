import React from "react";

function Footer() {
  return (
    <div id="footer">
      
      <img id="footerImg" src="./images/OlffloLogoWhite.png" alt="Olfflo" />
      <p>Copyright 2023</p>
      <img id="footerImg" src="./images/OlffloLogoWhite.png" alt="Olfflo" />
    </div>
  );
}

export default Footer;
